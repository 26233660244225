@import '../../../styles/colors.scss';

.admin_payment_options_wrapper {
    .header_section {
        padding-bottom: 0px;
        margin: 0px;
        .title {
            color: $bahama_blue;
            font-weight: bold;
            font-size: 36px;
            margin: 26px auto 20px;
            padding-bottom: 25px;
        }
        .subtitle {
            color: $silver_chalice;
            font-size: 18px;
            margin: 26px auto 20px;
        }
        .subtitle_email {
            color: $bahama_blue;
        }
        .box_wrapper {
            border: 0.5px solid $bahama_blue;
            padding: 20px;
            border-radius: 25px;
            width: 400px;
            .title {
                font-size: 24px;
                font-weight: bold;
                color:  $bahama_blue;
                padding-bottom: 10px;
                margin: 0px;
            }
            .subtitle {
                font-size: 16px;
                color:$silver_chalice;
                margin: 0px;
            }
            .button_section {
                display: flex;
                gap: 170px;
                button {
                    height: 40px;
                    width: 110px;
                    border-radius: 30px;
                    border: none;
                    background-color: $bahama_blue;
                    color: #FFFFFF;
                    font-size: 15px;
                    line-height: 15px;
                    font-weight: bold;
                    padding: 10px 0px;
                    cursor: pointer;
                    margin-top: 50px;
                    letter-spacing: 1px;
                }
                .disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }
        
            }
        }
    }

    .admin_payment_options_filter {
        padding-right: 30px;
        display: flex;
        justify-content: flex-end;
        width: 60%;
        .filter_circle {
            background-color: rgba($silver_chalice, 0.25);
            width: 40px;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active_filter {
                background-color: $pelorous; 
            }
            :global(.MuiTouchRipple-root) {
                display: none;
            }
        }
    }


}

// Smaller screens (smaller than 640px)
@media only screen and (max-width: 640px) {
    .admin_payment_options_wrapper {
        padding-top: 70px;
        .header_section {
            .title {
                font-size: 28px;
                text-align: center;
            }
            .subtitle {
                font-size: 18px;
                text-align: center;
            }
        }
    }
}


// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .admin_payment_options_wrapper {
        .header_section {
            padding-bottom: 0px;
            margin: 0px;
            .title {
                font-size: 28px;
                margin: 16px auto 10px;
            }
            .subtitle {
                font-size: 18px;
                margin: 10px auto 20px;
            }
        }
    }
}