@import '../../styles/colors.scss';

.transaction_listing_wrapper {
    .menu_wrapper {
        background-color: $white;
        height: 60px;
        width: 100%;
        position: fixed;
        top: 0px;
        z-index: 99999999;
    }
    .header_section {
        padding-bottom: 0px;
        margin: 0px;
        .title {
            color: $bahama_blue;
            font-weight: bold;
            font-size: 36px;
            margin: 26px auto 20px;
        }
        .subtitle {
            color: $silver_chalice;
            font-size: 18px;
            margin: 26px auto 20px;
        }
        .subtitle_email {
            color: $bahama_blue;
        }
    }

    .transaction_listing_filter {
        padding-right: 30px;
        display: flex;
        justify-content: flex-end;
        .filter_circle {
            background-color: rgba($silver_chalice, 0.25);
            width: 40px;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active_filter {
                background-color: $pelorous; 
            }
            :global(.MuiTouchRipple-root) {
                display: none;
            }
        }
    }


}

// Smaller screens (smaller than 640px)
@media only screen and (max-width: 640px) {
    .transaction_listing_wrapper {
        padding-top: 70px;
        .header_section {
            .title {
                font-size: 28px;
                text-align: center;
            }
            .subtitle {
                font-size: 18px;
                text-align: center;
            }
        }
    }
}


// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .transaction_listing_wrapper {
        .header_section {
            padding-bottom: 0px;
            margin: 0px;
            .title {
                font-size: 28px;
                margin: 16px auto 10px;
            }
            .subtitle {
                font-size: 18px;
                margin: 10px auto 20px;
            }
        }
    }
}