@import '../../../styles/colors.scss';

.admin_purpose_transfer_table_wrapper {
    height: 100%;
    width: 60%;
    margin-right: 30px;
    margin-bottom: 30px;
    :global(.MuiTableContainer-root) {
        border: 0.5px solid rgba( $silver_chalice, 0.5);
        max-height: calc(100% - 38px);
    }
    :global(.MuiTableBody-root .MuiTableRow-root) {
        &.selected {
            background-color: rgba(#91D3FC, 0.6);
        }
    }
    .admin_purpose_transfer_table_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        .recent_options {
            font-size: 16px;
            line-height: 18px;
            color: $silver_chalice;
        }
    }

    .buttons_container {
        left: 20px;
        right: 20px;
        bottom: 20px;
        height: 30px;
        display: flex;
        justify-content: flex-end;
        button {
            font-size: 13px;
            line-height: 19px;
            border: none;
            outline: none;
            cursor: pointer;
        }
        .delete_button {
            color: $white;
            background-color: $pelorous;
            border-radius: 9px;
            width: 70px;
            height: 29px;
        }
        .disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    :global(.MuiTableCell-head) {
        padding-left: 10px;
        padding-right: 10px;
    }
    :global(.MuiTableCell-body) {
        padding-left: 10px;
        padding-right: 10px;
    }
    :global(.MuiTablePagination-toolbar) {
        min-height: 25px;
    }
    :global(.MuiTablePagination-actions) {
        margin-left: 0px;
    }
    :global(.MuiTypography-root) {
        padding: 8px 0px;
        justify-content: flex-end;
    }
    :global(.MuiIconButton-root) {
        padding: 0px 12px;
        color: $silver_chalice;
    }
    :global(.MuiIconButton-root.Mui-disabled) {
        color: rgba( $silver_chalice, 0.5);
    }

    :global(.MuiTablePagination-caption) {
        font-size: 15px;
        line-height: 18px;
        color: rgba($silver_chalice, 0.8);
        font-family: 'Cabin', sans-serif;
        justify-content: flex-end;

    }
    :global(.MuiTableSortLabel-root.MuiTableSortLabel-active) {
        color: $polo_blue;
        &:global(.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon) {
            color: $white;
        }
    }
    :global(.MuiTableSortLabel-root) {
        &:hover {
            color: rgba($white_smoke, 0.5);
        }
    }
}

// Smaller screens (smaller than 640px)
@media only screen and (max-width: 640px) {
    .admin_purpose_transfer_table_wrapper {
        margin-left: 10px;
        .admin_options_payout_table_header {
            .recent_transaction {
                font-size: 15px;
                line-height: 16px;
            }
        }
        :global(.MuiTableCell-head) {
            padding-left: 10px;
            padding-right: 10px;
        }
        :global(.MuiTableCell-body) {
            padding-left: 10px;
            padding-right: 10px;
        }
        :global(.MuiTablePagination-toolbar) {
            min-height: 25px;
        }
        :global(.MuiTablePagination-actions) {
            margin-left: 0px;
        }
        :global(.MuiTypography-root) {
            padding: 8px 0px;
            justify-content: flex-end;
        }
        :global(.MuiIconButton-root) {
            padding: 0px 12px;
            color: $silver_chalice;
        }
        :global(.MuiIconButton-root.Mui-disabled) {
            color: rgba( $silver_chalice, 0.5);
        }
    
        :global(.MuiTablePagination-caption) {
            font-size: 15px;
            line-height: 18px;
            color: rgba($silver_chalice, 0.8);
            font-family: 'Cabin', sans-serif;
            justify-content: flex-end;
    
        }
        :global(.MuiTableSortLabel-root.MuiTableSortLabel-active) {
            color: $polo_blue;
            &:global(.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon) {
                color: $white;
            }
        }
        :global(.MuiTableSortLabel-root) {
            &:hover {
                color: rgba($white_smoke, 0.5);
            }
        }
    }
}

// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .admin_purpose_transfer_table_wrapper {
        margin-right: 20px;
        width: 60%;
        .admin_options_payout_table_header {
            .recent_transaction {
                font-size: 14px;
                line-height: 15px;
                color: $silver_chalice;
            }
        }
        :global(.MuiTableCell-head) {
            padding-left: 10px;
            padding-right: 10px;
        }
        :global(.MuiTableCell-body) {
            padding-left: 10px;
            padding-right: 10px;
        }
        :global(.MuiTablePagination-toolbar) {
            min-height: 25px;
        }
        :global(.MuiTablePagination-actions) {
            margin-left: 0px;
        }
        :global(.MuiTypography-root) {
            padding: 8px 0px;
            justify-content: flex-end;
        }
        :global(.MuiIconButton-root) {
            padding: 0px 12px;
            color: $silver_chalice;
        }
        :global(.MuiIconButton-root.Mui-disabled) {
            color: rgba( $silver_chalice, 0.5);
        }
    
        :global(.MuiTablePagination-caption) {
            font-size: 15px;
            line-height: 18px;
            color: rgba($silver_chalice, 0.8);
            font-family: 'Cabin', sans-serif;
            justify-content: flex-end;
    
        }
        :global(.MuiTableSortLabel-root.MuiTableSortLabel-active) {
            color: $polo_blue;
            &:global(.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon) {
                color: $white;
            }
        }
        :global(.MuiTableSortLabel-root) {
            &:hover {
                color: rgba($white_smoke, 0.5);
            }
        }
    }
}