@import '../../styles/colors.scss';

.range_datepicker_input {
    background: white;
    border-radius: 9px;
    padding: 0px 14px;
    height: 35px;
    width: 182px;
    margin-right: 10px;
    :global(.MuiFormControl-root) {
        width: 100%;
    }
    :global(.MuiInputBase-input) {
        font-size: 14px;
        text-align: left;
        font-size: 14px;
        line-height: 18px;
        color: $pelorous;
        font-weight: 500;
        padding: 10px 0px 10px 0px;
        &::placeholder {
            color: $prussian_blue;
        }
    }
    :global(.MuiInput-underline.Mui-disabled:before) {
        border-bottom-style: none;
    }
    :global(.MuiSvgIcon-root) {
        margin-right: 10px;
        font-size: 17px;
    }
}
:global(.rdrInputRanges) {
    display: none;
}