@import '../../styles/colors.scss';

.header_wrapper {
    background-color: $bahama_blue;
    position: absolute;
    right: 0;
    width: calc(100% - 190px);
    transition: width 0.5s;
    z-index: 11;
    height: 90px;
    .header_section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px 10px 20px;
    }
    .header_content {
        align-items: baseline;
        display: flex;
        text-transform: capitalize;
        p {
            color: $white;
            font-size: 22px;
            font-weight: bold;
            line-height: 27px;
            padding-right: 50px;
        }
        span {
            color: rgba($white, 0.5);
            font-size: 22px;
            line-height: 22px;
            font-weight: 500;
        }
    }
    .header_links {
        font-size: 18px;
        margin-left: auto;
        padding-right: 40px;
        a {
            color: $white;
            padding-right: 50px;
            text-decoration: none;
        }
    }
    .header_user_profile {
        display: flex;
        .header_user_content {
            padding-right: 11px;
            p {
                color: $white;
                font-size: 18px;
                line-height: 22px;
                font-weight: 500;
                text-align: right;
            }
            .header_logout_section {
                display: flex;
                p {
                    color: $white;
                    margin-left: 18px;
                    cursor: pointer;
                }
                p, span {
                    font-size: 18px;
                    line-height: 18px;
                    font-weight: 500;
                }
            }
            span {
                color: rgba($white, 0.5);

            }
        }
    }
    .header_icon_wrapper {
        width: 60px;
        height: 60px;
        background-color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: $bahama_blue;
        font-size: 25px;
    }
}

// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .header_wrapper {
        width: calc(100% - 90px);
        height: 80px;
        .header_section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px 10px 20px;
        }
        .header_content {
            p {
                font-size: 17px;
                padding-right: 15px;
            }
            span {
                color: rgba($white, 0.5);
                font-size: 15px;
                line-height: 22px;
                font-weight: 500;
            }
        }
        .header_links {
            font-size: 15px;
            margin-left: auto;
            padding-right: 20px;
            a {
                padding-right: 10px;
            }
        }
        .header_user_profile {
            display: flex;
            .header_user_content {
                padding-right: 11px;
                p {
                    font-size: 15px;
                }
                .header_logout_section {
                    display: flex;
                    p {
                        color: $white;
                        margin-left: 18px;
                        cursor: pointer;
                    }
                    p, span {
                        font-size: 15px;
                    }
                }
            }
        }
        .header_icon_wrapper {
            width: 40px;
            height: 40px;
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }
    }
}