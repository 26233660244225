@import './colors.scss';

.icon_svg {
    mask-repeat: no-repeat !important;
    mask-position: center center !important;
    display: inline-block;
    position: relative;
    mask-size: contain;
}

.icon {
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: contain;
    display: inline-block;
    position: relative;
}

.icon_beoexport {
    background-image: url('../assets/beo_export_logo.png');
    width: 190px;
    height: 90px;
}

.icon_beoexport_white {
    background-image: url('../assets/beo_export_logo-removebg.png');
    width: 260px;
    height: 150px;
}

.icon_beoexport_larger {
    background-image: url('../assets/beo_export_logo.png');
    width: 250px;
    height: 150px;
}

.icon_send {
    mask-image: url('../assets/icons/icon_send.svg');
    width: 60px;
    height: 60px;
}

.icon_list {
    mask-image: url('../assets/icons/icon_list.svg');
    width: 60px;
    height: 60px;
}

.icon_money {
    mask-image: url('../assets/icons/icon_money.svg');
    mask-image: url('../assets/icons/icon_money.svg');
    width: 40px;
    height: 40px;
}

.icon_transaction_list {
    mask-image: url('../assets/icons/icon_transaction_list.svg');
    width: 40px;
    height: 40px;
}

.icon_checklist {
    mask-image: url('../assets/icons/icon_checklist.svg');
    width: 40px;
    height: 40px;
}

.icon_recipient {
    mask-image: url('../assets/icons/icon_recipient.svg');
    width: 40px;
    height: 40px;
}

.icon_arrow {
    mask-image: url('../assets/icons/icon_arrow.svg');
    width: 8px;
    height: 12px;
    background-color: $gull_gray;
}

.icon_filter {
    mask-image: url('../assets/icons/icon_filter.svg');
    width: 20px;
    height: 20px;
    background-color: $white;
}

.icon_add {
    mask-image: url('../assets/icons/icon_plus.svg');
    width: 50px;
    height: 50px;
    background-color: $bahama_blue;
}

.icon_recipient_user {
    mask-image: url('../assets/icons/icon_user.svg');
    width: 40px;
    height: 40px;
    background-color: $bahama_blue;
}

.icon_eu {
    background-image: url('../assets/icons/european-union.png');
    width: 43px;
    height: 43px;

}

.icon_australia {
    background: url('../assets/icons/icon_australia.svg');
    width: 40px;
    height: 40px;
}

.icon_check_mark {
    mask-image: url('../assets/icons/icon_check_mark.svg');
    width: 40px;
    height: 40px;
    background-color: $japanese_laurel !important;
}

.icon_edit {
    mask-image: url('../assets/icons/icon_edit.svg');
    width: 18px;
    height: 18px;
    background-color: $gull_gray;
}

.icon_options {
    mask-image: url('../assets/icons/icon_options.svg');
    width: 25px;
    height: 25px;
}

.icon_percentage {
    mask-image: url('../assets/icons/icon_percentage.svg');
    width: 23px;
    height: 23px;
}

.icon_users {
    mask-image: url('../assets/icons/icon_users.svg');
    width: 25px;
    height: 25px;
}

.icon_admins {
    mask-image: url('../assets/icons/icon_admins.svg');
    width: 25px;
    height: 25px;
}

.icon_list_admin {
    mask-image: url('../assets/icons/icon_list.svg');
    width: 25px;
    height: 25px;
}

.icon_exchange_rate {
    mask-image: url('../assets/icons/icon_send.svg');
    width: 25px;
    height: 25px;
}

.icon_lock {
    mask-image: url('../assets/icons/icon_lock.svg');
    width: 20px;
    height: 20px;
    background-color: $gull_gray;
}

.icon_unlock {
    mask-image: url('../assets/icons/icon_unlock.svg');
    width: 20px;
    height: 20px;
    background-color: $gull_gray;
}


// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .icon_beoexport {
        width: 75px;
    }

    .icon_send {
        width: 45px;
        height: 45px;
    }

    .icon_list {
        width: 45px;
        height: 45px;
    }

    .icon_money {
        width: 30px;
        height: 30px;
    }

    .icon_transaction_list {
        width: 30px;
        height: 30px;
    }

    .icon_checklist {
        width: 30px;
        height: 30px;
    }

    .icon_recipient {
        width: 30px;
        height: 30px;
    }

    .icon_check_mark {
        width: 30px;
        height: 30px;
    }
}