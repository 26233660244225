@import '../../../styles/colors.scss';

.sidebar_item_wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    height: 80px;
    padding-left: 13px;
    &.active {
        color: $white;
        background-color: $mine_shaft;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            right: 0px;
            width: 5px;
            height: 100%;
            background-color: $bahama_blue;
        }
        :global(.icon_svg) {
            background-color: $astral;
        }
    }
    :global(.icon_svg) {
        background-color: $astral;
    }
    .icon_wrapper {
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sidebar_item {
        text-align: left;
        font-size: 16px;
        line-height: 16px;
        color: $silver_chalice;
        padding-left: 12px;
    }
}

// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .sidebar_item_wrapper {
        height: 150px;
        .sidebar_item {
            font-size: 15px;
            text-align: center;
        }
    }
}