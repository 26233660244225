@import '../../../styles/colors.scss';

.be_table_container {
    border-radius: 9px;
    :global(.MuiTableCell-root) {
        font-family: 'Cabin', sans-serif;
    }
    :global(.MuiTableCell-body){
        line-height: 20px;
    }
    :global(.MuiTableCell-head) {
        line-height: 32px;
    }
    :global(.MuiTableCell-body),
    :global(.MuiTableCell-head) {
        color: $silver_chalice;
        font-size: 16px;
        border-bottom: none;
        padding: 5px 20px;

        .icon_background {
            width: 25px;
            height: 25px;
            background-color: $silver;
            border-radius: 50%;
            display:flex;
            justify-content: center;
            align-items: center;
        }
    }
    :global(.MuiTableCell-head) {
        font-weight: bold;
    }
    :global(.MuiTableCell-stickyHeader) {
        background-color: $bahama_blue;
        color: $white_smoke;
    }
    :global(.MuiTableRow-head) {
        background-color: $bahama_blue;
        color: $white_smoke;
    }
    :global(.MuiTableRow-root) {
        height: 45px;
    }
    :global(.MuiTableBody-root .MuiTableRow-root):nth-child(odd) {
        background-color: $white;
        box-shadow: 0px 3px 6px rgba($silver_chalice, 0.9);
        
    }
    :global(.MuiTableBody-root .MuiTableRow-root):nth-child(even) {
        background-color: rgba($mystic, 0.9);
        box-shadow: 0px 3px 6px rgba($mystic, 0.9);
    }
    :global(.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon) {
        opacity: 1;
        color: white;
    }
    
}

// Smaller screens (smaller than 640px)
@media only screen and (max-width: 640px) {
    .be_table_container {
        border-radius: 9px;
        :global(.MuiTableCell-root) {
            font-family: 'Cabin', sans-serif;
        }
        :global(.MuiTableCell-body){
            line-height: 20px;
        }
        :global(.MuiTableCell-head) {
            line-height: 13px;
        }
        :global(.MuiTableCell-body),
        :global(.MuiTableCell-head) {
            color: $silver_chalice;
            font-size: 11px;
            border-bottom: none;
            padding: 4px 4px;
    
            .icon_background {
                width: 25px;
                height: 25px;
                background-color: $silver;
                border-radius: 50%;
                display:flex;
                justify-content: center;
                align-items: center;
            }
        }
        :global(.MuiTableCell-head) {
            font-weight: bold;
        }
        :global(.MuiTableCell-stickyHeader) {
            background-color: rgba($astral, 0.8);
            color: $white_smoke;
        }
        :global(.MuiTableRow-head) {
            background-color: rgba($astral, 0.8);
            color: $white_smoke;
        }
        :global(.MuiTableRow-root) {
            height: 45px;
        }
        :global(.MuiTableBody-root .MuiTableRow-root):nth-child(odd) {
            background-color: $white;
            box-shadow: 0px 3px 6px rgba($silver_chalice, 0.9);
            
        }
        :global(.MuiTableBody-root .MuiTableRow-root):nth-child(even) {
            background-color: rgba($mystic, 0.9);
            box-shadow: 0px 3px 6px rgba($mystic, 0.9);
        }
    }
    
}

// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .be_table_container {
        :global(.MuiTableCell-body){
            line-height: 15px;
        }
        :global(.MuiTableCell-head) {
            line-height: 15px;
        }
        :global(.MuiTableCell-body),
        :global(.MuiTableCell-head) {
            font-size: 14px;
            padding: 5px 5px;
        }
        :global(.MuiTableRow-root) {
            height: 41px;
        }
    }
}