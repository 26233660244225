$curious_blue:#22A8DD;
$link_water:#CBD0D4;
$gull_gray: #93A3B0;
$white:#FFFFFF;
$azure_radiance: #097FF5;
$tundora: #4D4D4D;
$silver_chalice: #A0A0A0;
$danube: #5BA3CB;
$heather: #ABBAC9;
$mine_shaft: #2C2C2C;
$summer_sky:#26B0D6;
$mint_tulip: #D2EEF7;
$whisper: #FAFAFC;
$wild_sand: #F5F5F5;
$silver: #CCCCCC;
$link_water: #DBEBF8;
$mystic: #EDF1F4;
$japanese_laurel: #05A813;
$pelorous:#1E87C9;
$white_smoke:#F5F5F5;
$alice_blue:#E9EEF1;
$prussian_blue:#083A65;
$persian_red:#DB2827;
$medium_aquamarine:#7ACFA8;
$pigment_green:#17B45A;
$bahama_blue:#04539B;
$astral: #3579B4;
$polo_blue:#75A4CC;
$woody_brown: #392D2D;