@import '../../styles/colors.scss';

:global(.MuiSnackbar-anchorOriginTopRight) {
    top: 98px !important; // override default top value
    :global(.MuiAlert-root) {
        padding: 10px 10px;
        align-items: center;
    }
    :global(.MuiAlert-icon) {
        display: none;
    }
    :global(.MuiAlert-message) {
        padding: 0px;
        font-size: 18px;
        line-height: 18px;
        color: $white;
    }
    :global(.MuiAlert-standardSuccess) {
        background-color: $medium_aquamarine;
        border: 2px solid $pigment_green;
        border-radius: 3px;
    }
    :global(.MuiAlert-standardError) {
        background-color: rgba($persian_red, 0.3);
        border: 2px solid $persian_red;
        border-radius: 3px;
    }
    :global(.MuiAlert-action) {
        color: $white;
    }
}
