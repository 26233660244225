@import '../../../../styles/colors.scss';

.transaction_view_wrapper {
    .header_section {
        margin: 0px;

        .title {
            color: $bahama_blue;
            font-weight: bold;
            font-size: 36px;
            margin: 26px auto 20px;
            text-transform: capitalize;
        }

        .subtitle {
            color: $silver_chalice;
            font-size: 18px;
            margin: 26px auto 20px;
        }

        .subtitle_email {
            color: $bahama_blue;
        }
    }

    .go_back_button {
        color: $bahama_blue;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
    }

    .modal_wrapper {
        background-color: $alice_blue;
        box-sizing: border-box;
        position: relative;
        border-radius: 9px;
        height: auto;
        width: auto;
        max-width: 700px;
        max-height: 700px;
    }

    .span_icon {
        background-color: $bahama_blue;
        transform: rotate(180deg);
    }

    .information_wrapper {
        border: 1px solid $bahama_blue;
        border-radius: 25px;
        padding: 40px 40px 40px 40px;
        background-color: $whisper;
        height: 50%;
        width: 80%;

        .button_section {
            justify-content: flex-end;
            display: flex;

            button {
                font-size: 16px;
                line-height: 19px;
                border: none;
                outline: none;
                cursor: pointer;
            }

            .apply_button {
                color: $white;
                background-color: $bahama_blue;
                border-radius: 9px;
                width: 70px;
                height: 29px;
            }
        }

        .section_title {
            color: $bahama_blue;
            font-weight: bold;
            font-size: 20px;
            padding-bottom: 20px;
            text-align: center;
        }

        .infomation_section {
            display: flex;
            font-size: 15px;

            .left_section {
                flex: 1;
                padding-right: 20px;
                padding-top: 20px;
                text-align: center;

                .with_background {
                    background-color: $bahama_blue;
                }

                .text_row {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 15px 20px;
                    color: $silver_chalice;

                    .title {
                        width: 50%;
                        font-weight: bold;
                        text-align: left;
                    }

                    .colon {
                        width: 5%;
                        text-align: center;
                    }

                    .value {
                        width: 50%;
                        text-align: left;
                    }
                }

                .circle_placeholder {
                    width: 140px;
                    height: 140px;
                    line-height: 140px;
                    border-radius: 50%;
                    font-size: 50px;
                    color: $silver_chalice;
                    text-align: center;
                    background: #bbb;
                    margin: auto;
                    border: 1px solid $bahama_blue;
                }

                .documents_pictures_wrapper {
                    display: flex;
                    padding-top: 35px;
                    justify-content: space-around;

                    .circle_placeholder_doc {
                        margin: auto;
                        width: 100px;
                        height: 100px;
                    }

                    .image_wrapper {
                        height: auto;
                        width: auto;
                        max-width: 100px;
                        max-height: 100px;
                    }

                    .title {
                        font-weight: bold;
                        color: $silver_chalice;
                    }
                }

                .full_name {
                    font-size: 26px;
                    font-weight: bold;
                    color: $bahama_blue;
                    padding-top: 15px;
                    text-transform: capitalize;
                }

                .email {
                    padding-top: 10px;
                    color: $silver_chalice;
                }

                .country {
                    padding-top: 10px;
                    color: $silver_chalice;
                }
            }

            .border_line {
                border-left: 1px solid $bahama_blue;
                height: 400px;
            }

            .right_section {
                flex: 1;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 20px;
                margin: auto;

                .with_background {
                    background-color: $bahama_blue;
                    color: $white_smoke  !important;
                }

                .text_row {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 15px 20px;
                    color: $silver_chalice;
                    color: $bahama_blue;

                    .title {
                        width: 50%;
                        font-weight: bold;
                        text-align: left;
                    }

                    .colon {
                        width: 5%;
                        text-align: center;
                    }

                    .value {
                        width: 50%;
                        text-align: left;
                    }
                }
            }
        }
    }


}

// Smaller screens (smaller than 640px)
@media only screen and (max-width: 640px) {
    .transaction_view_wrapper {
        padding-top: 70px;

        .menu_wrapper {
            background-color: $white;
            height: 60px;
            width: 100%;
            position: fixed;
            top: 0px;
            z-index: 99999999;
        }

        .header_section {
            padding-bottom: 50px;
            margin: 0px;

            .title {
                font-size: 28px;
                text-align: center;
            }

            .subtitle {
                font-size: 17px;
                text-align: center;
            }
        }

        .go_back_button {
            padding-left: 10px;
        }

        .information_wrapper {
            padding: 5px;
            width: 95%;
            margin: auto;

            .button_section {
                justify-content: flex-end;
                display: flex;

                button {
                    font-size: 16px;
                    line-height: 19px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                }

                .apply_button {
                    color: $white;
                    background-color: $pelorous;
                    border-radius: 9px;
                    width: 70px;
                    height: 29px;
                }
            }

            .section_title {
                padding-top: 15px;
            }

            .section_title_second {
                padding-top: 50px;
            }

            .infomation_section {
                display: block;

                .left_section {
                    padding-right: 0px;
                    margin: auto;

                    .text_row {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 15px 20px;
                        color: $silver_chalice;

                        .title {
                            font-size: 15px;
                        }

                        .colon {
                            font-size: 15px;
                        }

                        .value {
                            font-size: 15px;
                        }
                    }
                }

                .border_line {
                    display: none;
                }

                .right_section {
                    padding-left: 0px;
                    padding-bottom: 20px;

                    .text_row {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 15px 20px;
                        color: $silver_chalice;

                        .title {
                            font-size: 15px;
                        }

                        .colon {
                            font-size: 15px;
                        }

                        .value {
                            font-size: 15px;
                        }
                    }
                }
            }
        }


    }
}

// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .transaction_view_wrapper {
        .header_section {
            padding-bottom: 20px;

            .title {
                font-size: 28px;
                margin: 16px auto 10px;
            }

            .subtitle {
                font-size: 18px;
                margin: 10px auto 20px;
            }
        }

        .information_wrapper {
            width: 95%;
            padding: 10px;

            .section_title {
                font-size: 18px;
                padding-bottom: 10px;
            }

            .infomation_section {
                .left_section {
                    padding-right: 10px;

                    .text_row {
                        padding: 10px 6px;
                        color: $silver_chalice;

                        .title {
                            font-size: 14px;
                        }

                        .colon {
                            font-size: 14px;
                        }

                        .value {
                            font-size: 14px;
                        }
                    }

                    .circle_placeholder {
                        width: 200px;
                        height: 200px;
                        line-height: 200px;
                        border-radius: 50%;
                        font-size: 50px;
                        color: $silver_chalice;
                        text-align: center;
                        background: #bbb;
                    }

                    .full_name {
                        font-size: 26px;
                        font-weight: bold;
                        color: $bahama_blue;
                        padding-top: 15px;
                    }

                    .email {
                        padding-top: 10px;
                        color: $silver_chalice;
                    }

                    .country {
                        padding-top: 10px;
                        color: $silver_chalice;
                    }
                }

                .border_line {
                    height: 405px;
                }

                .right_section {
                    flex: 1;
                    padding-left: 20px;

                    .with_background {
                        background-color: $bahama_blue;
                    }

                    .text_row {
                        padding: 10px 6px;

                        .title {
                            font-size: 14px;
                        }

                        .colon {
                            font-size: 14px;
                        }

                        .value {
                            font-size: 14px;
                        }
                    }
                }
            }
        }


    }
}