@import '../../../styles/colors.scss';

.sidebar_item_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    height: 190px;
    &.active {
        color: $white;
        background-color: $mine_shaft;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            right: 0px;
            width: 5px;
            height: 100%;
            background-color: $astral;
        }
        :global(.icon_svg) {
            background-color: $astral;
        }
    }
    :global(.icon_svg) {
        background-color: $astral;
    }
    .icon_wrapper {
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sidebar_item {
        font-size: 18px;
        line-height: 22px;
        padding-top: 15px;
        color: $silver_chalice;
    }
}

// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .sidebar_item_wrapper {
        height: 150px;
        .sidebar_item {
            font-size: 15px;
            text-align: center;
        }
    }
}