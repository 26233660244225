@import '../../styles/colors.scss';

.layout_wrapper {
    display: flex;
    height: 100%;
    background-size: cover;
    .layout_content {
        width: 100%;
        overflow: auto;
        margin-top: 120px;
        margin-left: 40px;
        position: relative;
        z-index: 10;
    }
}

// Smaller screens (smaller than 640px)
@media only screen and (max-width: 640px) {
    .layout_wrapper {
        display: flex;
        height: 100%;
        background-size: cover;
        .layout_content {
            width: 100%;
            overflow: auto;
            margin-top: 0px;
            margin-left: 0px;
            position: relative;
            z-index: 10;
        }
    }
}

// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .layout_wrapper {
        .layout_content {
            margin-top: 80px;
            margin-left: 25px;
        }
    }
}