@import '../../../styles/colors.scss';

.admin_user_filter_container {
    width: 687px;
    height: 250px;
    background-color: $alice_blue;
    box-sizing: border-box;
    padding: 30px 20px 20px;
    position: relative;

    .close_icon {
        position: absolute;
        top: 20px;
        right: 20px;
        color: $bahama_blue;
        cursor: pointer;
    }

    .title_container {
        display: flex;
        .filter_icon {
            background-color: $prussian_blue;
            margin-right: 20px;
            margin-top: 5px;
        }
        .title {
            font-size: 14px;
            line-height: 18px;
            color: $prussian_blue;
            font-weight: bold;
        }
        .subtitle {
            font-size: 12px;
            line-height: 15px;
            color: $prussian_blue;
            margin-bottom: 18px;
        }
    }

    .autocomplete_container {
        display: flex;
        margin-bottom: 8px;
        :global(.MuiFormControl-root) {
            padding-right: 10px;
        }
        .filter_label {
            text-align: left;
            font-size: 10px;
            letter-spacing: 0px;
            color: $prussian_blue;
            padding-bottom: 5px;
        }
    }

    .buttons_container {
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 20px;
        height: 30px;
        display: flex;
        justify-content: flex-end;
        button {
            font-size: 16px;
            line-height: 19px;
            border: none;
            outline: none;
            cursor: pointer;
        }
        .apply_button {
            color: $white;
            background-color: $bahama_blue;
            border-radius: 9px;
            width: 70px;
            height: 29px;
        }
        .clear_button {
            color: $bahama_blue;
            margin-right: 24px;
            background-color: transparent;
        }
        .disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .filter_field {
        :global(.MuiInputBase-root) {
            background-color: $white;
            border-radius: 9px;
            width: 210px;
            height: 35px;
            fieldset {
                border: none;
            }
            :global(.MuiInputBase-input) {
                text-align: left;
                font-size: 14px;
                line-height: 18px;
                color: $prussian_blue;
                font-weight: 500;
                &::placeholder {
                    color: $prussian_blue;
                }
            }
        } 
    }
}

:global(.MuiPaper-root.MuiPaper-rounded) {
    border-radius: 9px;
}


// Smaller screens (smaller than 640px)
@media only screen and (max-width: 640px) {
    .transaction_listing_filter_container {
        width: 300px;
        height: 430px;
        box-sizing: border-box;
        padding: 30px 20px 20px;
        position: relative;
        .title_container {
            display: flex;
            padding-top: 10px;
            .filter_icon {
                margin-right: 15px;
                margin-top: 5px;
            }
            .title {
                font-size: 14px;
                line-height: 18px;
                color: $prussian_blue;
                font-weight: bold;
            }
            .subtitle {
                font-size: 12px;
                line-height: 15px;
                color: $prussian_blue;
                margin-bottom: 18px;
            }
        }
    
        .autocomplete_container {
            display: block;
            margin-bottom: 8px;
            .filter_label {
                padding-bottom: 5px;
                padding-top: 5px;
            }
        }
    
        .buttons_container {
            bottom: 10px;
        }
    
        .filter_field {
            :global(.MuiInputBase-root) {
                background-color: $white;
                border-radius: 9px;
                width: 210px;
                height: 35px;
            } 
        }
    }
}