@import "../../styles/colors.scss";

.registration_page_wrapper {
    height: 100%;
    display: flex;

    .registration_page_right_section {
        flex: 1;
        justify-content: flex-start;
        background-color: ghostwhite;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-size: cover;
        padding-top: 40px;

        .go_back_section_login {
            display: flex;
            align-self: flex-start;
            padding-left: 15px;

            .back_to_login {
                color: $bahama_blue;
                font-weight: bold;
            }

            .icon_back {
                height: 20px;
                color: $bahama_blue;
            }

            .back_to_login {
                font-size: 20px;
            }
        }

        .finish_registration_section {
            font-size: 25px;
            color: $bahama_blue;
            text-align: center;
        }

        a {
            text-decoration: none;
            cursor: pointer;
        }

        form {
            display: flex;
            flex-direction: column;
            min-width: 333px;

            :global(.MuiTextField-root) {
                margin-bottom: 15px;

                &:nth-of-type(2) {
                    margin-bottom: 10px;
                }
            }

            :global(.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline) {
                border-color: $bahama_blue;
            }

            :global(.MuiOutlinedInput-root) {
                border-radius: 10px;
            }

            :global(.MuiOutlinedInput-input) {
                height: 14px;
                color: $gull_gray;
            }

            :global(.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline) {
                border-color: $bahama_blue;
            }

            :global(.MuiStepLabel-label) {
                display: none;
            }

            :global(.MuiFormHelperText-root) {
                width: 300px;
            }

        }

        .registration_title {
            color: $bahama_blue;
            font-weight: bold;
            font-size: 50px;
            margin: 10px auto 10px;
            letter-spacing: 1px;
        }

        h4 {
            margin: 0px;
            color: $gull_gray;
            letter-spacing: 1px;
            padding-bottom: 40px;
        }

        .links_section {
            display: flex;
            justify-content: space-between;
            font-size: 15px;
            color: $bahama_blue;

            .forgot_password,
            .privacy_policy {
                margin-bottom: 23px;
                color: $bahama_blue;
                cursor: pointer;
                text-decoration: underline;
            }
        }

        .input {
            margin-bottom: 15px;
        }

        .register_section {
            width: 330px;
            display: flex;
            justify-content: center;
            font-size: 15px;
            letter-spacing: 1px;
            padding-bottom: 40px;

            p {
                margin: 35px 5px 0px 0px;
            }

            .register_question {
                color: $gull_gray;
            }

            .register_link {
                margin: 35px 0px 0px 0px;
                color: $bahama_blue;
                font-weight: bold;
            }

            .check_box {
                color: $bahama_blue;
                font-weight: bold;
            }

            :global(.MuiFormControlLabel-label) {
                font-size: 13px;
            }

            .title_form {
                font-size: 13px;
                color: $bahama_blue;

                .links {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

        .button {
            border-radius: 30px;
            border: none;
            background-color: $bahama_blue;
            color: #FFFFFF;
            font-size: 18px;
            line-height: 22px;
            font-weight: bold;
            padding: 14px 0px;
            cursor: pointer;
            margin-top: 50px;
            letter-spacing: 1px;
        }

        .register_button {
            color: $white;
            background-color: $pelorous;
            border-radius: 9px;
            width: 70px;
            height: 29px;
        }

        .disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        :global(.MuiPaper-root) {
            background-color: transparent;
        }

        :global(.MuiStepIcon-root.MuiStepIcon-completed) {
            color: $bahama_blue;
        }

        :global(.MuiStepIcon-root.MuiStepIcon-active) {
            color: $bahama_blue;
        }

        :global(.MuiTypography-root) {
            text-align: center;
        }

        :global(.Mui-checked) {
            color: $bahama_blue;
        }
    }

    .registration_page_left_section {
        flex: 1;
        background: url('../../assets/background2.png') center;

        .header_title {
            text-align: center;
            color: $white;
            font-weight: bold;
            font-size: 36px;
            margin: 26px 50px 20px;
            letter-spacing: 1px;
        }

        .title_section {
            display: flex;
            justify-content: center;
            height: 70%;
            flex-wrap: wrap;
            flex-grow: 1;
            flex-direction: column;
            align-items: center;
            color: $white;
            text-align: center;
            font-weight: bold;
            letter-spacing: 0.5px;

            .greeting_msg {
                font-size: 40px;
                margin: 26px auto 0px;
            }

            .greeting_title {
                font-size: 60px;
                margin: 26px auto 10px;
                letter-spacing: 5px;
            }

            .secondary_text {
                width: 600px;
                font-size: 15px;
                font-weight: normal;
                letter-spacing: 0px;
                padding-top: 60px;
            }

            hr {
                width: 10%;
                display: block;
                height: 1px;
                border: 0;
                border-top: 10px solid $white;
                margin: 0;
                padding: 0;
            }
        }

    }
}

// Smaller screens (smaller than 640px)
@media only screen and (max-width: 640px) {
    .registration_page_wrapper {
        display: unset;

        .registration_page_right_section {
            padding-top: 20px;
            height: inherit;

            .go_back_section_login {
                display: flex;
                align-self: flex-start;
                padding-left: 15px;

                .back_to_login {
                    color: $bahama_blue;
                    font-weight: bold;
                }

                .icon_back {
                    height: 18px;
                }

                .back_to_login {
                    font-size: 18px;
                }
            }

            .finish_registration_section {
                font-size: 25px;
                color: $bahama_blue;
                text-align: center;
            }

            a {
                text-decoration: none;
                cursor: pointer;
            }

            form {
                min-width: 300px;
                padding-bottom: 10px;

                :global(.MuiTextField-root) {
                    width: 270px;
                    margin: 10px auto;

                    &:nth-of-type(2) {
                        margin-bottom: 10px;
                    }
                }
            }

            .registration_title {
                font-size: 24px;
            }

            h4 {
                font-size: 16px;
                text-align: center;
            }

            .links_section {
                margin: 0px 20px;

                .forgot_password,
                .privacy_policy {
                    margin-bottom: 23px;
                    color: $bahama_blue;
                }
            }

            .input {
                margin-bottom: 15px;
            }

            .register_section {
                width: 300px;
                padding-left: 10px;
            }

            button {
                // width: 270px;
                // margin: auto;
            }

            .second_step,
            .third_step {
                padding-bottom: 40px;
            }

            .third_step {
                height: inherit;
            }

            :global(.MuiFormHelperText-root.Mui-error) {
                margin: 0px !important;
            }
        }

        .registration_page_left_section {
            .header_title {
                font-size: 18px;
                padding: 20px;
                margin: 0px;
                text-align: center;
            }

            .title_section {
                flex-wrap: initial;

                .greeting_msg {
                    font-size: 20px;
                    padding: 0px 4px;
                }

                .greeting_title {
                    font-size: 35px;
                    padding: 0px 0px 0px 5px;
                }

                .secondary_text {
                    display: none;
                }

                hr {
                    width: 20%;
                    margin-bottom: 20px;
                }
            }

        }
    }
}

// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .registration_page_wrapper {
        display: flex;

        .registration_page_right_section {
            padding-top: 20px;
            height: inherit;
            width: 50%;

            .go_back_section_login {
                display: flex;
                align-self: flex-start;
                padding-left: 15px;

                .back_to_login {
                    color: $bahama_blue;
                    font-weight: bold;
                }

                .icon_back {
                    height: 18px;
                }

                .back_to_login {
                    font-size: 18px;
                }
            }

            .finish_registration_section {
                font-size: 25px;
                color: $bahama_blue;
                text-align: center;
            }

            a {
                text-decoration: none;
                cursor: pointer;
            }

            form {
                min-width: 300px;

                :global(.MuiTextField-root) {
                    width: 270px;
                    margin: 10px auto;

                    &:nth-of-type(2) {
                        margin-bottom: 10px;
                    }
                }
            }

            .registration_title {
                font-size: 24px;
            }

            h4 {
                font-size: 16px;
                text-align: center;
            }

            .links_section {
                margin: 0px 20px;

                .forgot_password,
                .privacy_policy {
                    margin-bottom: 23px;
                    color: $bahama_blue;
                }
            }

            .input {
                margin-bottom: 15px;
            }

            .register_section {
                width: 300px;
                padding-left: 10px;
            }

            button {
                // width: 270px;
            }

            .second_step,
            .third_step {
                padding-bottom: 40px;
            }

            .third_step {
                height: inherit;
            }
        }

        .registration_page_left_section {
            width: 50%;

            .header_title {
                font-size: 18px;
                padding: 20px;
                margin: 0px;
                text-align: center;
            }

            .title_section {
                flex-wrap: initial;

                .greeting_msg {
                    font-size: 20px;
                    padding: 0px 4px;
                }

                .greeting_title {
                    font-size: 35px;
                    padding: 0px 0px 0px 5px;
                }

                .secondary_text {
                    display: none;
                }

                hr {
                    width: 20%;
                    margin-bottom: 20px;
                }
            }

        }
    }
}