@import '../../styles/colors.scss';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


.add_recipient_container {
    :global(.MuiOutlinedInput-input:-webkit-autofill) {
        padding: 7.5px 14px;
    }

    background-color: $alice_blue;
    box-sizing: border-box;
    padding: 30px 50px 20px;
    position: relative;
    border-radius: 9px;

    .close_icon {
        position: absolute;
        top: 20px;
        right: 20px;
        color: $bahama_blue;
        cursor: pointer;
    }

    .daterange_icon {
        color: $prussian_blue;
    }

    .daterange_wrapper {
        display: flex;
        align-items: center;
        width: 313px;
    }

    .title_container {
        display: flex;

        .title {
            font-size: 18px;
            line-height: 18px;
            color: $bahama_blue;
            font-weight: bold;
        }

        .subtitle {
            font-size: 15px;
            line-height: 15px;
            color: $bahama_blue;
            margin-bottom: 18px;
        }
    }

    .padding_bottom {
        padding-bottom: 10px;
    }

    .inputs_section_title {
        color: $bahama_blue;
        padding-bottom: 20px;
        font-weight: bold;
    }

    .autocomplete_container {
        display: flex;

        :global(.MuiFormControl-root) {
            padding-right: 10px;
            padding-bottom: 10px;
        }

        .input_label {
            display: flex;
            text-align: left;
            font-size: 15px;
            letter-spacing: 0px;
            color: $prussian_blue;
            padding-bottom: 10px;
        }

        .help_icon {
            height: 20px;
            width: 20px;
            color: $bahama_blue;
            margin-left: 10px;
        }

        .type_section {
            :global(.MuiInputBase-root) {
                width: 250px;
                border: 1px solid $persian_red;
            }

            :global(.MuiFormControl-root) {
                padding-bottom: 0px;
            }

            :global(.MuiInputLabel-outlined) {
                top: -14px;
                background-color: $white;
                line-height: 35px;
                color: rgba($prussian_blue, 0.5);
                font-size: 14px;
                height: 20px;
            }

            :global(.MuiInputLabel-outlined.MuiInputLabel-shrink) {
                display: none;
            }

            :global(.MuiSelect-select.MuiSelect-select) {
                line-height: 32px;
                color: $prussian_blue;
                font-weight: 500;
                font-size: 14px;
                height: 32px;

                &:focus {
                    background-color: $white;
                    border-radius: 9px;
                }
            }

            :global(.MuiOutlinedInput-input) {
                padding: 0px 14px;
            }

            :global(.MuiOutlinedInput-root) {
                background-color: $white;
                border-radius: 9px;
                height: 35px;

                fieldset {
                    border: none;
                }
            }
        }

        :global(.MuiFab-secondary) {
            background-color: $bahama_blue;
        }

        .title_wrapper {
            display: flex;
        }

        .file_upload_container {
            display: block;
        }

        .file_upload {
            padding-top: 15px;
            margin-left: 20px;
            display: flex;
            flex-direction: column;

            .file_upload_name {
                display: flex;
                color: $prussian_blue;
                margin-top: 5px;

                span {
                    margin-left: 15px;
                }
            }
        }
    }


    .buttons_container {
        right: 20px;
        bottom: 15px;
        height: 30px;
        position: absolute;

        button {
            font-size: 16px;
            line-height: 19px;
            border: none;
            outline: none;
            cursor: pointer;
        }

        .save_button {
            color: $white;
            background-color: $bahama_blue;
            border-radius: 9px;
            width: 70px;
            height: 29px;
        }

        .disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }


    .input_field_red_border {
        :global(.MuiInputBase-root) {
            background-color: $white;
            border-radius: 9px;
            width: 250px;
            height: 35px;
            border: 1px solid $persian_red;

            fieldset {
                border: none;
            }

            :global(.MuiInputBase-input) {
                text-align: left;
                font-size: 14px;
                line-height: 18px;
                color: $prussian_blue;
                font-weight: 500;

                &::placeholder {
                    color: $prussian_blue;
                }
            }
        }
    }

    .input_field {
        :global(.MuiInputBase-root) {
            background-color: $white;
            border-radius: 9px;
            width: 250px;
            height: 35px;

            fieldset {
                border: none;
            }

            :global(.MuiInputBase-input) {
                text-align: left;
                font-size: 14px;
                line-height: 18px;
                color: $prussian_blue;
                font-weight: 500;

                &::placeholder {
                    color: $prussian_blue;
                }
            }
        }
    }


    .input_field_msg {
        :global(.MuiInputBase-root) {
            background-color: $white;
            border-radius: 9px;
            width: 1029px;
            height: 35px;

            fieldset {
                border: none;
            }

            :global(.MuiInputBase-input) {
                text-align: left;
                font-size: 14px;
                line-height: 18px;
                color: $prussian_blue;
                font-weight: 500;

                &::placeholder {
                    color: $prussian_blue;
                }
            }
        }
    }

    .input_field_masked {
        text-align: left;
        font-size: 14px;
        line-height: 18px;
        color: $prussian_blue;
        font-weight: 500;
        background-color: $white;
        border-radius: 9px;
        width: 213px;
        height: 35px;
        border: none;
        padding: 0px 0px 0px 10px;
        margin-right: 10px;
        border: 0px;
        font-weight: 600;
    }

    .input_field_masked:focus-visible {
        outline: 0px;
    }

    ::placeholder {
        color: $heather;
    }

    .error_msg_date {
        color: #f44336;
        margin-left: 14px;
        margin-right: 14px;
        font-size: 0.75rem;
        margin-top: 3px;
        text-align: left;
        font-weight: 400;
        line-height: 1.66;
        letter-spacing: 0.03333em;
    }


    .label_under_input {
        font-size: 0.75rem;
        text-align: left;
        font-weight: 400;
        margin-left: 14px;
    }
}

:global(.MuiPaper-root.MuiPaper-rounded) {
    border-radius: 9px;
}

// Smaller screens (smaller than 640px)
@media only screen and (max-width: 640px) {
    .add_recipient_container {
        width: 300px;
        height: 640px;
        padding: 15px 15px 15px;
        overflow-y: scroll;
        overflow-x: hidden;

        .title_container {
            display: flex;

            .title {
                font-size: 15px;
                line-height: 15px;
            }

            .subtitle {
                font-size: 15px;
                line-height: 15px;
                color: $prussian_blue;
                margin-bottom: 18px;
                padding-right: 10px;
            }
        }

        .padding_bottom {
            padding-bottom: 10px;
        }

        .inputs_section_title {
            color: $bahama_blue;
            padding-bottom: 20px;
            font-weight: bold;
        }

        .autocomplete_container {
            display: block;

            :global(.MuiFormControl-root) {
                padding-right: 10px;
                padding-bottom: 10px;
            }

            .input_label {
                display: flex;
                text-align: left;
                font-size: 15px;
                letter-spacing: 0px;
                color: $prussian_blue;
                padding-bottom: 10px;
            }

            .help_icon {
                height: 20px;
                width: 20px;
                color: $bahama_blue;
                margin-left: 10px;
            }

            .type_section {
                :global(.MuiInputBase-root) {
                    width: 250px;
                    border: 1px solid $persian_red;
                }

                :global(.MuiFormControl-root) {
                    padding-bottom: 0px;
                }

                :global(.MuiInputLabel-outlined) {
                    top: -14px;
                    background-color: $white;
                    line-height: 35px;
                    color: rgba($prussian_blue, 0.5);
                    font-size: 14px;
                    height: 20px;
                }

                :global(.MuiInputLabel-outlined.MuiInputLabel-shrink) {
                    display: none;
                }

                :global(.MuiSelect-select.MuiSelect-select) {
                    line-height: 32px;
                    color: $prussian_blue;
                    font-weight: 500;
                    font-size: 14px;
                    height: 32px;

                    &:focus {
                        background-color: $white;
                        border-radius: 9px;
                    }
                }

                :global(.MuiOutlinedInput-input) {
                    padding: 0px 14px;
                }

                :global(.MuiOutlinedInput-root) {
                    background-color: $white;
                    border-radius: 9px;
                    height: 35px;

                    fieldset {
                        border: none;
                    }
                }
            }

            :global(.MuiFab-secondary) {
                background-color: $summer_sky;
            }

            .title_wrapper {
                display: flex;
            }

            .file_upload_container {
                display: block;
            }

            .file_upload {
                padding-top: 15px;
                margin-left: 20px;
                display: flex;
                flex-direction: column;

                .file_upload_name {
                    display: flex;
                    color: $prussian_blue;
                    margin-top: 5px;

                    span {
                        margin-left: 15px;
                    }
                }
            }
        }


        .buttons_container {
            left: 180px;
            bottom: 0px;
            height: 30px;
            position: relative;

            button {
                font-size: 16px;
                line-height: 19px;
                border: none;
                outline: none;
                cursor: pointer;
            }

            .save_button {
                color: $white;
                background-color: $pelorous;
                border-radius: 9px;
                width: 70px;
                height: 29px;
            }

            .disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }


        .input_field_red_border {
            :global(.MuiInputBase-root) {
                background-color: $white;
                border-radius: 9px;
                width: 250px;
                height: 35px;
                border: 1px solid $persian_red;

                fieldset {
                    border: none;
                }

                :global(.MuiInputBase-input) {
                    text-align: left;
                    font-size: 14px;
                    line-height: 18px;
                    color: $prussian_blue;
                    font-weight: 500;

                    &::placeholder {
                        color: $prussian_blue;
                    }
                }
            }
        }

        .input_field {
            :global(.MuiInputBase-root) {
                background-color: $white;
                border-radius: 9px;
                width: 250px;
                height: 35px;

                fieldset {
                    border: none;
                }

                :global(.MuiInputBase-input) {
                    text-align: left;
                    font-size: 14px;
                    line-height: 18px;
                    color: $prussian_blue;
                    font-weight: 500;

                    &::placeholder {
                        color: $prussian_blue;
                    }
                }
            }
        }

        .input_field_masked {
            text-align: left;
            font-size: 14px;
            line-height: 18px;
            color: $prussian_blue;
            font-weight: 500;
            background-color: $white;
            border-radius: 9px;
            width: 222px;
            height: 35px;
            border: none;
            padding: 0px 14px;
            margin-right: 10px;
            border: 0px;
            font-weight: 600;
        }

        .input_field_masked:focus-visible {
            outline: 0px;
        }

        ::placeholder {
            color: $heather;
        }

        .error_msg_date {
            color: #f44336;
            margin-left: 14px;
            margin-right: 14px;
            font-size: 0.75rem;
            margin-top: 3px;
            text-align: left;
            font-weight: 400;
            line-height: 1.66;
            letter-spacing: 0.03333em;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .add_recipient_container {
        width: 300px;
        height: 640px;
        padding: 15px 25px 15px;
        overflow-y: scroll;
        overflow-x: hidden;

        .title_container {
            display: flex;

            .title {
                font-size: 15px;
                line-height: 15px;
            }

            .subtitle {
                font-size: 15px;
                line-height: 15px;
                color: $prussian_blue;
                margin-bottom: 18px;
                padding-right: 10px;
            }
        }

        .padding_bottom {
            padding-bottom: 10px;
        }

        .inputs_section_title {
            color: $bahama_blue;
            padding-bottom: 20px;
            font-weight: bold;
        }

        .autocomplete_container {
            display: block;

            :global(.MuiFormControl-root) {
                padding-right: 10px;
                padding-bottom: 10px;
            }

            .input_label {
                display: flex;
                text-align: left;
                font-size: 15px;
                letter-spacing: 0px;
                color: $prussian_blue;
                padding-bottom: 10px;
            }

            .help_icon {
                height: 20px;
                width: 20px;
                color: $bahama_blue;
                margin-left: 10px;
            }

            .type_section {
                :global(.MuiInputBase-root) {
                    width: 250px;
                    border: 1px solid $persian_red;
                }

                :global(.MuiFormControl-root) {
                    padding-bottom: 0px;
                }

                :global(.MuiInputLabel-outlined) {
                    top: -14px;
                    background-color: $white;
                    line-height: 35px;
                    color: rgba($prussian_blue, 0.5);
                    font-size: 14px;
                    height: 20px;
                }

                :global(.MuiInputLabel-outlined.MuiInputLabel-shrink) {
                    display: none;
                }

                :global(.MuiSelect-select.MuiSelect-select) {
                    line-height: 32px;
                    color: $prussian_blue;
                    font-weight: 500;
                    font-size: 14px;
                    height: 32px;

                    &:focus {
                        background-color: $white;
                        border-radius: 9px;
                    }
                }

                :global(.MuiOutlinedInput-input) {
                    padding: 0px 14px;
                }

                :global(.MuiOutlinedInput-root) {
                    background-color: $white;
                    border-radius: 9px;
                    height: 35px;

                    fieldset {
                        border: none;
                    }
                }
            }

            :global(.MuiFab-secondary) {
                background-color: $summer_sky;
            }

            .title_wrapper {
                display: flex;
            }

            .file_upload_container {
                display: block;
            }

            .file_upload {
                padding-top: 15px;
                margin-left: 20px;
                display: flex;
                flex-direction: column;

                .file_upload_name {
                    display: flex;
                    color: $prussian_blue;
                    margin-top: 5px;

                    span {
                        margin-left: 15px;
                    }
                }
            }
        }


        .buttons_container {
            left: 180px;
            bottom: 0px;
            height: 30px;
            position: relative;

            button {
                font-size: 16px;
                line-height: 19px;
                border: none;
                outline: none;
                cursor: pointer;
            }

            .save_button {
                color: $white;
                background-color: $pelorous;
                border-radius: 9px;
                width: 70px;
                height: 29px;
            }

            .disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }


        .input_field_red_border {
            :global(.MuiInputBase-root) {
                background-color: $white;
                border-radius: 9px;
                width: 250px;
                height: 35px;
                border: 1px solid $persian_red;

                fieldset {
                    border: none;
                }

                :global(.MuiInputBase-input) {
                    text-align: left;
                    font-size: 14px;
                    line-height: 18px;
                    color: $prussian_blue;
                    font-weight: 500;

                    &::placeholder {
                        color: $prussian_blue;
                    }
                }
            }
        }

        .input_field {
            :global(.MuiInputBase-root) {
                background-color: $white;
                border-radius: 9px;
                width: 250px;
                height: 35px;

                fieldset {
                    border: none;
                }

                :global(.MuiInputBase-input) {
                    text-align: left;
                    font-size: 14px;
                    line-height: 18px;
                    color: $prussian_blue;
                    font-weight: 500;

                    &::placeholder {
                        color: $prussian_blue;
                    }
                }
            }
        }

        .input_field_masked {
            text-align: left;
            font-size: 14px;
            line-height: 18px;
            color: $prussian_blue;
            font-weight: 500;
            background-color: $white;
            border-radius: 9px;
            width: 222px;
            height: 35px;
            border: none;
            padding: 0px 14px;
            margin-right: 10px;
            border: 0px;
            font-weight: 600;
        }

        .input_field_masked:focus-visible {
            outline: 0px;
        }

        ::placeholder {
            color: $heather;
        }

        .error_msg_date {
            color: #f44336;
            margin-left: 14px;
            margin-right: 14px;
            font-size: 0.75rem;
            margin-top: 3px;
            text-align: left;
            font-weight: 400;
            line-height: 1.66;
            letter-spacing: 0.03333em;
        }
    }
}