@import '../../styles/colors.scss';

.sidebar_wrapper {
    display: flex;
    .sidebar_first_menu {
        height: 100%;
        left: 0;
        background-color: $tundora;
        background-size: cover;
        width: 190px;
        z-index: 1;
        .sidebar_logo {
            background-color: $white;
            height: 90px;
        }
        .menu_container {
            position: relative;
            overflow-y: auto;
        }
    }
    
    .sidebar_second_menu {
        height: 100%;
        opacity: 0.9;
        left: 0;
        background-color: $mine_shaft;
        background-size: cover;
        width: 190px;
        z-index: 1;
        .sidebar_logo {
            background-color: $white;
            padding-top: 27px;
            padding-left: 37px;
            height: 54px;
            padding-bottom: 10px;
        }
        .menu_container {
            position: relative;
            .sidebar_item_wrapper {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                z-index: 10;
                height: 100px;
                &.active {
                    color: $white;
                    background-color: $mine_shaft;
                    position: relative;
                &:after,
                &:before {
                    left: 100%;
                    top: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }
                &:after {
                    border-color: rgba(136, 183, 213, 0);
                    border-left-color: $mine_shaft;;
                    border-width: 10px;
                    margin-top: -10px;
                }
                &:before {
                    border-color: rgba(194, 225, 245, 0);
                    border-width: 13px;
                    margin-top: -13px;
                }
                    :global(.icon_svg) {
                        background-color: $silver_chalice;
                    }
                }
                :global(.icon_svg) {
                    background-color: $silver_chalice;
                }
                .icon_wrapper {
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .sidebar_item {
                    font-size: 15px;
                    line-height: 22px;
                    padding-top: 5px;
                    color: $silver_chalice;
                }
            }
        }
    }
}


// Medium screens (641px to 1024px)
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .sidebar_wrapper {
        .sidebar_first_menu {
            width: 90px;
            .sidebar_logo {
                height: 80px;
                text-align: center;
            }
        }
        .sidebar_second_menu {
            width: 100px;
            .sidebar_logo {
                height: 44px;
            }
            .menu_container {
                .sidebar_item_wrapper {
                    height: 100px;
                    &.active {
                        color: $white;
                        background-color: $mine_shaft;
                        position: relative;
                    &:after,
                    &:before {
                        left: 100%;
                        top: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }
                    &:after {
                        border-color: rgba(136, 183, 213, 0);
                        border-left-color: $mine_shaft;;
                        border-width: 10px;
                        margin-top: -10px;
                    }
                    &:before {
                        border-color: rgba(194, 225, 245, 0);
                        border-width: 13px;
                        margin-top: -13px;
                    }
                        :global(.icon_svg) {
                            background-color: $silver_chalice;
                        }
                    }
                    :global(.icon_svg) {
                        background-color: $silver_chalice;
                    }
                    .icon_wrapper {
                        border-radius: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .sidebar_item {
                        font-size: 11px;
                        line-height: 12px;
                        padding-top: 5px;
                        color: $silver_chalice;
                        text-align: center;
                    }
                }
            }
        }
    }
}